import React, { useState } from "react";
import {
  Link,
  FormattedMessage,
  useIntl,
  changeLocale,
} from "gatsby-plugin-intl";
import { Transition } from "@tailwindui/react";

import * as styles from "./styles";
import Logo from "../../svgs/logo-full.inline.svg";
import LogoSM from "../../svgs/logo.inline.svg";
import Dropdown from "../dropdown";

interface Props extends React.HTMLAttributes<HTMLElement> {
  siteTitle?: string;
  onOpenNav: () => void;
  onCloseNav: () => void;
  navOpen: boolean;
}

const Header: React.FC<Props> = ({
  siteTitle,
  onOpenNav,
  onCloseNav,
  navOpen,
  ...attrs
}) => {
  const lang = useIntl();

  const langSelections = [
    { value: "en", label: "English" },
    { value: "es", label: "Español" },
  ];

  return (
    <header css={{}} {...attrs}>
      <div className="relative bg-white shadow">
        <div className="max-w-8xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
            <div className="w-0 flex-1 flex">
              <Link to="/" className="inline-flex capitalize">
                {/* <img
                  className="h-8 w-auto sm:h-10"
                  src="https://tailwindui.com/img/logos/workflow-mark-on-white.svg"
                  alt="Workflow"
                /> */}
                <Logo className="h-8 w-auto sm:h-16" />
              </Link>
            </div>
            <div className="block md:hidden mr-3">
              <Dropdown
                selections={langSelections}
                defaultSelection={
                  langSelections[
                    langSelections.findIndex(
                      value => value.value == lang.locale
                    )
                  ]
                }
                onSelected={option => {
                  changeLocale(option.value);
                }}
              />
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <button
                onClick={onOpenNav}
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
            <nav className="hidden md:flex space-x-10">
              <Link
                to="/"
                className="text-base leading-6 text-gray-500 hover:text-gray-900 capitalize"
              >
                <FormattedMessage id="nav_link_home" />
              </Link>
              <Link
                to="/about"
                className="text-base leading-6 text-gray-500 hover:text-gray-900 capitalize"
              >
                <FormattedMessage id="nav_link_about" />
              </Link>
              <Link
                to="/pricing"
                className="text-base leading-6 text-gray-500 hover:text-gray-900 capitalize"
              >
                <FormattedMessage id="nav_link_pricing" />
              </Link>
              <Link
                to="/faq"
                className="text-base leading-6 text-gray-500 hover:text-gray-900 capitalize"
              >
                <FormattedMessage id="nav_link_faq" />
              </Link>
              <Link
                to="/contact-us"
                className="text-base leading-6 text-gray-500 hover:text-gray-900 capitalize"
              >
                <FormattedMessage id="nav_link_contact_us" />
              </Link>
            </nav>
            <div className="hidden md:flex items-center justify-end space-x-8 md:flex-1 lg:w-0">
              <Dropdown
                selections={langSelections}
                defaultSelection={
                  langSelections[
                    langSelections.findIndex(
                      value => value.value == lang.locale
                    )
                  ]
                }
                onSelected={option => {
                  changeLocale(option.value);
                }}
              />
              <Link
                to="/login"
                className="whitespace-no-wrap text-base leading-6 font-medium text-gray-500 hover:text-gray-900 capitalize transition ease-in-out duration-150"
              >
                <FormattedMessage id="nav_link_login" />
              </Link>
              <span className="inline-flex rounded-md shadow-sm">
                <Link
                  to="/register"
                  className="whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-teal-600 hover:bg-teal-500 focus:outline-none focus:border-teal-700 focus:shadow-outline-teal active:bg-teal-700 transition ease-in-out duration-150 capitalize"
                >
                  <FormattedMessage id="nav_link_register" />
                </Link>
              </span>
            </div>
          </div>
        </div>

        {/* <!--
            Mobile menu, show/hide based on mobile menu state.

            Entering: "duration-200 ease-out"
              From: "opacity-0 scale-95"
              To: "opacity-100 scale-100"
            Leaving: "duration-100 ease-in"
              From: "opacity-100 scale-100"
              To: "opacity-0 scale-95"
          --> */}
        <Transition
          show={navOpen}
          enter="duration-50 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-50 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <>
            <div className="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right md:hidden">
              <div className="rounded-lg shadow-lg">
                <div className="rounded-lg shadow-xs bg-white divide-y-2 divide-gray-50">
                  <div className="pt-5 pb-6 px-5 space-y-6">
                    <div className="flex items-center justify-between">
                      <div>
                        {/* <img
                          className="h-8 w-auto"
                          src="https://tailwindui.com/img/logos/workflow-mark-on-white.svg"
                          alt="Workflow"
                        /> */}
                        <LogoSM className="h-8 w-auto" />
                      </div>
                      <div className="-mr-2">
                        <button
                          onClick={onCloseNav}
                          type="button"
                          className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                        >
                          <svg
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div>
                      <nav className="grid row-gap-8">
                        <Link
                          to="/"
                          className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                        >
                          <svg
                            className="flex-shrink-0 h-6 w-6 text-teal-600"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                            />
                          </svg>
                          <div className="text-base leading-6 font-medium text-gray-900 capitalize">
                            <FormattedMessage id="nav_link_home" />
                          </div>
                        </Link>
                        <Link
                          to="/about"
                          className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                        >
                          <svg
                            className="flex-shrink-0 h-6 w-6 text-teal-600"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          <div className="text-base leading-6 font-medium text-gray-900 capitalize">
                            <FormattedMessage id="nav_link_about" />
                          </div>
                        </Link>
                        <Link
                          to="/pricing"
                          className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                        >
                          <svg
                            className="flex-shrink-0 h-6 w-6 text-teal-600"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                            />
                          </svg>
                          <div className="text-base leading-6 font-medium text-gray-900 capitalize">
                            <FormattedMessage id="nav_link_pricing" />
                          </div>
                        </Link>
                        <Link
                          to="/faq"
                          className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                        >
                          <svg
                            className="flex-shrink-0 h-6 w-6 text-teal-600"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          <div className="text-base leading-6 font-medium text-gray-900 capitalize">
                            <FormattedMessage id="nav_link_faq" />
                          </div>
                        </Link>
                        <Link
                          to="/contact-us"
                          className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                        >
                          <svg
                            className="flex-shrink-0 h-6 w-6 text-teal-600"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                            />
                          </svg>
                          <div className="text-base leading-6 font-medium text-gray-900 capitalize">
                            <FormattedMessage id="nav_link_contact_us" />
                          </div>
                        </Link>
                      </nav>
                    </div>
                  </div>
                  <div className="py-6 px-5 space-y-6">
                    <div className="space-y-6">
                      <span className="w-full flex rounded-md shadow-sm">
                        <Link
                          to="/register"
                          className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-teal-600 hover:bg-teal-500 focus:outline-none focus:border-teal-700 focus:shadow-outline-teal active:bg-teal-700 transition ease-in-out duration-150 capitalize"
                        >
                          <FormattedMessage id="nav_link_register" />
                        </Link>
                      </span>
                      <p className="text-center text-base leading-6 font-medium text-gray-500">
                        <FormattedMessage id="nav_text_existing_customer" />
                        <Link
                          to="/login"
                          className="text-teal-600 hover:text-teal-500 transition ease-in-out duration-150 capitalize"
                        >
                          {` `}
                          <FormattedMessage id="nav_link_login" />
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Transition>
      </div>
    </header>
  );
};

export default Header;
