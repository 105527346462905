import React, { useEffect, useState } from "react";
import { Transition } from "@tailwindui/react";

type Option = { value: any; label: string };

interface Props {
  defaultSelection: Option;
  selections: Option[];
  onSelected: (option: Option) => void;
}

const Dropdown: React.FC<Props> = ({
  defaultSelection,
  selections,
  onSelected,
}) => {
  const [selected, setSelected] = useState<Option>();
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    if (defaultSelection) {
      setSelected(defaultSelection);
    }
  }, [defaultSelection]);

  return (
    //     <!--
    //   Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
    //   Read the documentation to get started: https://tailwindui.com/documentation
    // -->
    <div className="relative inline-block text-left z-50">
      <div>
        <span className="rounded-md shadow-sm">
          <button
            type="button"
            onClick={() => setShow(!show)}
            className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true"
          >
            {selected && <span className="capitalize">{selected.label}</span>}
            {/* <!-- Heroicon name: chevron-down --> */}
            <svg
              className="-mr-1 ml-2 h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </span>
      </div>

      {/* <!--
    Dropdown panel, show/hide based on dropdown state.

    Entering: "transition ease-out duration-100"
      From: "transform opacity-0 scale-95"
      To: "transform opacity-100 scale-100"
    Leaving: "transition ease-in duration-75"
      From: "transform opacity-100 scale-100"
      To: "transform opacity-0 scale-95"
  --> */}
      <Transition
        show={show}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg">
          <div className="rounded-md bg-white shadow-xs">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {selections &&
                selections.map((selection, idx) => (
                  <button
                    key={idx}
                    type="button"
                    onClick={() => {
                      setSelected(selection);
                      setShow(false);
                      onSelected(selection);
                    }}
                    className="w-full text-left block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    role="menuitem"
                  >
                    {selection.label}
                  </button>
                ))}
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default Dropdown;
